import React from "react";
import { useHistory } from "react-router-dom";
import html2PDF from "jspdf-html2canvas";

import saveLogo from "../assets/ic_save_48px.png";
import printLogo from "../assets/ic_print_48px.png";
import { APDetailsType, DetailsType } from "../constants";
import ReferenceRange from "./ReferenceRange";
import { useContext } from "../contexts/AppContext";

import "../css/ReferenceInfo.css";
import "../css/AP.css";
import ReferenceInfoBloodBank from "./ReferenceInfoBloodBank";
import ReferenceInfoAll from "./ReferenceInfoAll";
import ReferenceInfoGenLab from "./ReferenceInfoGenLab";
import AP from "./AP";
import Detail from "./Detail";

type ExtraTestInfoProps = {
    details: DetailsType[];
    apDetails: APDetailsType[];
    testName: string;
    cptCode: string;
    fromLocation: string | undefined;
    testTypeId: string;
};

function ReferenceInfo({
    details,
    apDetails,
    testName,
    cptCode,
    fromLocation,
    testTypeId,
}: ExtraTestInfoProps) {
    const history = useHistory();
    // const { testTypeId } = useContext();

    async function onSave() {
        const app = $(".App");
        await html2PDF(app, {
            jsPDF: {
                format: "a4",
            },
            imageType: "image/jpeg",
            output: `${testName}.${cptCode}.reference-interval.pdf`,
        });
    }

    function onPrint() {
        window.print();
    }

    return (
        <div
            className={
                "card shadow testInfoCard" +
                (apDetails.length > 0 ? " ap-ancestor-card-body" : "")
            }
        >
            <div className="card-header">
                <div
                    className="btn catalogBtn"
                    onClick={() => {
                        if (fromLocation) {
                            history.push(fromLocation);
                        } else {
                            history.push("/");
                        }
                    }}
                >
                    Back to Catalog
                </div>
                <div className="testTitle">{testName}</div>
            </div>

            <div
                className={
                    "card-body" +
                    (apDetails.length > 0 ? " ap-grand_parent_body" : "")
                }
            >
                <div
                    className={
                        "cardContainer" +
                        (apDetails.length > 0 ? " ap-parent-card-body" : "")
                    }
                >
                    <div>
                        {details.length > 0 ? (
                            <div className="orderingRecommendationsTitle">
                                CPT Code: {cptCode}
                            </div>
                        ) : null}

                        {/* Contitional Rendering */}

                        <div className="card shadow-sm descriptionCard">
                            {details.length > 0 ? (
                                <div
                                    className="card-header"
                                    style={{ fontWeight: "bold" }}
                                >
                                    {testName}
                                </div>
                            ) : null}

                            <div
                                className={
                                    "card-body" +
                                    (apDetails.length > 0
                                        ? " ap-card-body"
                                        : "")
                                }
                            >
                                {apDetails.length > 0 ? (
                                    <AP apDetails={apDetails} />
                                ) : (
                                    <Detail
                                        details={details}
                                        testTypeId={testTypeId}
                                    />
                                )}
                            </div>
                        </div>

                        {apDetails.length === 0 ? (
                            <div>
                                <hr className="referenceIntervalSeperator" />
                                <div className="orderingRecommendationsTitle">
                                    Reference Interval
                                </div>
                            </div>
                        ) : null}
                        {}

                        {parseInt(testTypeId) === 0 ? (
                            <div>
                                {details.map((detail: any, i: any) => (
                                    <ReferenceInfoAll
                                        key={`refRange${i}`}
                                        detail={detail}
                                    />
                                ))}
                            </div>
                        ) : null}

                        {parseInt(testTypeId) === 1
                            ? details.map((detail) => (
                                  <ReferenceInfoGenLab
                                      detail={detail.ReferenceIntervals}
                                      description={detail.ModifiedDescription}
                                  />
                              ))
                            : null}

                        {parseInt(testTypeId) === 2
                            ? details.map((detail) => (
                                  <ReferenceInfoGenLab
                                      detail={detail.ReferenceIntervals}
                                      description={detail.ModifiedDescription}
                                  />
                              ))
                            : null}

                        {parseInt(testTypeId) === 3 ? (
                            <ReferenceRange detail={details} />
                        ) : null}

                        {parseInt(testTypeId) === 4
                            ? details[0].ReferenceIntervals.map(
                                  (detail: any, i: any) => {
                                      return (
                                          <ReferenceInfoBloodBank
                                              detail={detail}
                                          />
                                      );
                                  }
                              )
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReferenceInfo;
