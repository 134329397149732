import React, { useEffect, useRef, useState } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import TestRow from "./TestRow";

import "../css/TestTable.css";
import "../css/Base.css";
import TestTableContext from "../contexts/TestTableContext";
import Constants, { TestType, TestTypeId } from "../constants";
import { useContext } from "../contexts/AppContext";
import timeoutPromise from "../timeoutPromise";
import { start } from "repl";
import { param } from "jquery";

// get a python like range for use with page numbers
function range(startAt: number, offset: number) {
    return Array.from(Array(offset).keys()).map((i) => i + startAt);
}

const pageSize = 25;
const codeEndpoint =
    `https://referenelabcatalogfunc.azurewebsites.net/api/GetAllLabTest?` +
    `code=Cb2y3I-MqLxh0jaWfwITDc0sTGLsQwg_NprknQayKTDLAzFubemKGg==&` +
    `size=${pageSize}`;

// const getCodeEndpoint = `https://referenelabcatalogfunc.azurewebsites.net/api/GetLabTest?code=GezGU3V
// 0lFZrVVMF/W2v9xH8nkxbTRF23QAmxyzSfA3syGpS9WdUYA==&size=${pageSize}`;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function TestTable(): JSX.Element {
    const [time, setTime] = useState(Date.now());

    const { selectedButtonIndex, buttonsReducer, testTypeId, globalPage } =
        useContext();
    const history = useHistory();
    const query = useQuery();
    const page = query.get("page");
    const startsWith = query.get("startsWith");

    const [activeKey, setActiveKey] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(
        isNaN(parseInt(page ? page : "undefined"))
            ? 1
            : parseInt(page ? page : "undefined")
    );
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [tests, setTests] = useState<TestType[]>([]);
    const isMounted = useRef(false);

    const previousSearchQuery = usePrevious(startsWith);

    const offset = Math.max(Math.min(totalPages - (currentPage - 2) + 1, 5), 1);
    const pageNumbers =
        currentPage > 3
            ? range(currentPage - 2, offset)
            : range(1, Math.min(totalPages, 5));

    function onPageChange(pageNumber: number) {
        getTests(pageNumber);
        setActiveKey(null);
        setTime(Date.now());
    }

    useEffect(() => {
        setTotalPages(1);
        setCurrentPage(1);
    }, [globalPage]);

    useEffect(() => {
        if (
            Constants.usedChars.findIndex(
                (char: string) => char === startsWith
            ) === -1
        ) {
            buttonsReducer(-1);
        }

        // don't fire on initial mount to prevent duplicate requests
        if (!isMounted.current) {
            isMounted.current = true;
        } else {
            // if the searched term has changed, reset the page
            if (startsWith !== previousSearchQuery) {
                setCurrentPage(1);
                onPageChange(1);
            } else {
                onPageChange(currentPage);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startsWith, selectedButtonIndex, page]);

    useEffect(() => {
        onPageChange(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get a previous version of a hook value
    function usePrevious<T>(value: T) {
        const ref = useRef<T>();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    // grab tests from the endpoint
    function getTests(pageNumber: number) {
        setIsLoading(true);
        setTests([]);

        // let testtypeid = 0;
        let params = "";
        params += `&page=${pageNumber}`;
        // params += `&startsWith=${
        //     startsWith
        //         ? startsWith
        //         : selectedButtonIndex !== -1
        //         ? Constants.usedChars[selectedButtonIndex]
        //         : "-1"
        // }`;

        params += `&startsWith=${startsWith ? startsWith : "-1"}`;

        params += `&testTypeId=${testTypeId}`;

        timeoutPromise(30000, fetch(codeEndpoint + params))
            .then((response: any) => response.json())
            .then((json: any) => {
                if (json["totalPages"] === "") {
                    setTotalPages(1);
                    return;
                }
                setTotalPages(json["totalPages"]);

                // const data =
                //     testTypeId !== 5 && testTypeId !== 6
                //         ? json["Data"]
                //         : json["APData"];

                let tempTests: TestType[] = [];
                for (const data in json) {
                    if (data !== "Data" && data !== "APData") {
                        continue;
                    }
                    const listData = json[data];

                    for (let i = 0; i < listData.length; i++) {
                        const test = listData[i];

                        tempTests.push({
                            id: test["Id"],
                            cptCode:
                                test["TestTypeId"] !== TestTypeId.AP
                                    ? test["Code"]
                                    : "N/A",
                            testName:
                                test["TestTypeId"] !== TestTypeId.AP
                                    ? test["Name"]
                                    : test["PrimaryOrderName"],
                            preferredSpecimen: test["preferredSpecimen"]
                                ? test["preferredSpecimen"]
                                : "N/A",
                            minimumVolume: test["mininumVolume"]
                                ? test["mininumVolume"]
                                : "N/A",
                            transportTemperature: test["transportTemperature"]
                                ? test["transportTemperature"]
                                : "N/A",
                            performingLaboratory: test["performingLaboratory"]
                                ? test["performingLaboratory"]
                                : "N/A",
                            turnAroundTime: test["turnAroundTime"]
                                ? test["turnAroundTime"]
                                : "N/A",
                            referenceInterval: test["referenceInterval"]
                                ? test["referenceInterval"]
                                : "N/A",
                            description: test["description"]
                                ? test["description"]
                                : "N/A",
                            additionalInformation: "N/A",
                            TestTypeId: test["TestTypeId"],
                        });
                    }
                }
                setTests(tempTests);
            })
            .catch((_err: any) => {})
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getTests(1);
    }, [testTypeId]);

    function changePage(newPageNum: number) {
        let newLocation = {
            pathName: "/",
            search: `?page=${newPageNum}`,
        };

        if (startsWith) {
            newLocation.search += `&startsWith=${startsWith}`;
        } else if (selectedButtonIndex !== -1) {
            newLocation.search += `&startsWith=${Constants.usedChars[selectedButtonIndex]}`;
        }

        setCurrentPage(newPageNum);
        history.push(newLocation);
    }

    const pageNumberElements = pageNumbers.map((i) => {
        let style: React.CSSProperties = {};

        // highlight if current page
        if (i === currentPage) {
            style["color"] = "#d11a47";
        }

        return (
            <div
                className="btn pageNumber"
                onClick={() => changePage(i)}
                style={style}
                key={`pageNumber${i}`}
            >
                {i}
            </div>
        );
    });

    return (
        <TestTableContext.Provider
            value={{
                activeKey: activeKey,
                setActiveKey: setActiveKey,
            }}
        >
            <Accordion>
                <div
                    className="testTable"
                    key={`${selectedButtonIndex}DummyId`}
                >
                    <div className="row" key="pageRow">
                        <div className="col"></div>
                        <div className="col">
                            <div className="currentPage">
                                {totalPages !== 0
                                    ? `Page ${currentPage} of ${totalPages}`
                                    : ""}
                            </div>

                            {pageNumberElements}
                        </div>
                    </div>

                    <div className="row testsHeader" key="titleRow">
                        {testTypeId !== TestTypeId.AP && (
                            <div className="col">
                                <div className="cptCodeTitle">CPT Codes</div>
                            </div>
                        )}
                        <div className="col">
                            <div
                                className={
                                    "testNameTitle" +
                                    (testTypeId === TestTypeId.AP
                                        ? " left"
                                        : "")
                                }
                            >
                                Test Name
                            </div>
                        </div>
                    </div>

                    {tests.length ? (
                        tests.map((test, i) => {
                            const key = `row${test.id}${time}`;
                            return (
                                <TestRow
                                    key={key}
                                    id={key}
                                    cptCode={test.cptCode}
                                    testName={test.testName}
                                    TestTypeId={test.TestTypeId || ""}
                                />
                                // <div>Under Development</div>
                            );
                        })
                    ) : (
                        <div
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                            {isLoading ? (
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            ) : (
                                "No Results Found"
                            )}
                        </div>
                    )}
                </div>
            </Accordion>
        </TestTableContext.Provider>
    );
}

export default TestTable;
