import { DetailsType } from "../constants";

import "../css/ReferenceInfo.css";

interface IDetails {
    details: DetailsType[];
    testTypeId: string;
}

function Detail(props: IDetails) {
    const details = props.details;
    const testTypeId = props.testTypeId;

    return (
        <div className="row">
            <div className="col-sm testCol" style={{ marginLeft: "48px" }}>
                {details[0].SpecimenType && (
                    <div>
                        <div className="testAttributeTitle">Specimen Type</div>
                        <div className="testAttributeInfo">
                            {details[0].SpecimenType}
                        </div>
                    </div>
                )}

                {details[0].MinimumSpecimenVolumn && (
                    <div>
                        <div className="testAttributeTitle">
                            Minimum Specimen Volume
                        </div>
                        <div className="testAttributeInfo">
                            {details[0].MinimumSpecimenVolumn}
                        </div>
                    </div>
                )}

                {details[0]?.Transport && "" + testTypeId !== "3" && (
                    <div>
                        <div className="testAttributeTitle">
                            {"" + testTypeId !== "3"
                                ? "Transport"
                                : "Transport"}
                        </div>
                        <div className="testAttributeInfo">
                            {details[0]?.Transport}
                        </div>
                    </div>
                )}

                {details[0].ContainerType && (
                    <div>
                        <div className="testAttributeTitle">Container Type</div>
                        <div className="testAttributeInfo">
                            {details[0].ContainerType}
                        </div>
                    </div>
                )}
            </div>

            <div className="col-md">
                <div className="row">
                    <div className="col-sm-12 testCol">
                        {details[0].TurnAroundTime && (
                            <div>
                                <div className="testAttributeTitle">
                                    Turn-around Time
                                </div>
                                <div className="testAttributeInfo">
                                    {details[0].TurnAroundTime
                                        ? `Report available: ${details[0].TurnAroundTime}`
                                        : "N/A"}
                                </div>
                            </div>
                        )}

                        {details[0].SpecimenStability && (
                            <div>
                                <div className="testAttributeTitle">
                                    Specimen Stability
                                </div>
                                <div className="testAttributeInfo">
                                    {details[0].SpecimenStability
                                        ? details[0].SpecimenStability
                                        : "N/A"}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col-md">
                        <div className="row">
                            <div className="col-sm testCol">
                                {details[0].RejectionCriteria && (
                                    <div>
                                        <div className="testAttributeTitle">
                                            Rejection Criteria
                                        </div>
                                        <div className="testAttributeInfo">
                                            {details[0].RejectionCriteria}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Detail;
