import React from "react";

import "../css/ReferenceInfo.css";

type ReferenceInfoGenLabProps = {
    detail: any;
    description: string
};

function ReferenceInfoGenLab({ detail, description }: ReferenceInfoGenLabProps) {
    const rowData: any = [];
    detail = detail.sort(function(a:any, b:any){
        if(a[0] < b[0]) { return -1; }
        if(a[0] > b[0]) { return 1; }
        return 0;
    })
  
    detail.forEach((dataArr: string[]) => {
        let colData: string[] = [];
        rowData.push(colData);
        dataArr.forEach((data: string, index) => {
            if (colData.length >= 9) {
                colData = [];
                colData.push("");
                rowData.push(colData);
            }
            colData.push(data);
        });

        while (colData.length < 9) {
            colData.push("");
        }
    });

    return (
        <div className="card shadow referenceRangeCard">
            <div
                className="card-header"
                style={{ fontWeight: "bold", display: "flex", }}
            >
                <div
                    className="row"
                    style={{
                        width: "100%",
                        paddingLeft:20,
                    }}
                >
                    {description}
                </div>
            </div>
                {rowData.length > 0 && <div className="card-body" style={{ color: "#062157" }}>
                
                <table className="table table-sm" style={{ width: "100%", color: "#062157" }}>
                    {rowData.map((row: any, index: any) => {
                        return (
                            <tr
                                style={{
                                    backgroundColor: index % 2 === 0 ? "#e6e6e6" : "white",
                                }}
                            >
                                {row.map((col: any, idx: number) => {
                                    return idx === 0 ? <th style={{paddingLeft: 20}}>{col}</th> : <td>{col}</td>;
                                })}
                            </tr>
                        );
                    })}
                </table>
                </div>}
        </div>
    );
}

export default ReferenceInfoGenLab;
