import React from 'react';

type contextType = {
    activeKey: string | null,
    setActiveKey: (newKey: string | null) => void
}

const TestTableContext = React.createContext<contextType>({
    activeKey: null,
    setActiveKey: (newKey: string | null) => {}
});

export const useContext = () => React.useContext(TestTableContext);
export default TestTableContext;