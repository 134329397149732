import { APDetailsType } from "../constants";

import "../css/AP.css";

interface IAPData {
    apDetails: APDetailsType[];
}

function AP(props: IAPData) {
    const apDetails = props.apDetails;

    return (
        <div className="row ap-row">
            <div className="testInfoCard ap-testInfoCard">
                <div className="card-header table-head">
                    <div className="small-grow">Container Type</div>
                    <div className="mobile-grow">Transport</div>
                    <div className="grow">Specimen Preparation</div>
                    <div className="mobile-grow">Special Instruction</div>
                    <div className="mobile-grow">Rejection Criteria</div>
                </div>
                <div className="table-body">
                    {apDetails.map((item) => {
                        const specimenPreparation =
                            item.SpecimenPreparation.split(" - ");
                        return (
                            <section key={item.Id} id={item.Id}>
                                <div className="small-grow">
                                    {item.ContainerType}
                                </div>
                                <div className="mobile-grow">
                                    {item.Transport}
                                </div>
                                <div className="grow">
                                    <p>
                                        <span className="specimenPreparation-title">
                                            {specimenPreparation[0] + " - "}
                                        </span>{" "}
                                        {specimenPreparation[1]}
                                    </p>
                                </div>
                                <div className="mobile-grow">
                                    {item.SpecialInstructions === ""
                                        ? "N/A"
                                        : item.SpecialInstructions}
                                </div>
                                <div className="mobile-grow">
                                    {item.RejectionCriteria === "n/a"
                                        ? "N/A"
                                        : item.RejectionCriteria}
                                </div>
                            </section>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default AP;
