import React, { useReducer, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LandingPage from "./pages/LandingPage";
import ReferenceIntervalPage from "./pages/ReferenceIntervalPage";
import AppContext from "./contexts/AppContext";
import Constants from "./constants";

import "./css/App.css";

// letter === "A" ? ["A", true] : [letter, false]
const initButtons: [string, boolean][] = Constants.usedChars.map((letter) => [
    letter,
    false,
]);

function App() {
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(-1);
    const [buttons, buttonsReducer] = useReducer(reduceButtons, initButtons);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [testTypeId, setTestTypeId] = useState(0);
    const [gPage, setGPage] = useState(1);

    function reduceButtons(
        buttons: [string, boolean][],
        newSelectedButtonIndex: number
    ) {
        // if there was a previous selection, remove it
        if (selectedButtonIndex !== -1) {
            buttons[selectedButtonIndex][1] = false;
        }

        setSelectedButtonIndex(newSelectedButtonIndex);

        if (newSelectedButtonIndex === -1) {
            return buttons;
        }

        buttons[newSelectedButtonIndex][1] = true;

        return buttons;
    }

    return (
        <AppContext.Provider
            value={{
                buttons: buttons,
                buttonsReducer: buttonsReducer,
                selectedButtonIndex: selectedButtonIndex,
                searchQuery,
                setSearchQuery,
                testTypeId,
                globalPage: gPage,
            }}
        >
            <Router>
                <div className="App">
                    <Header
                        onTestTypeChange={(id: number) => {
                            setTestTypeId(id);
                            setGPage(gPage + 1);
                        }}
                    />
                    <Switch>
                        <Route
                            path="/reference/:cptCode/:id/:name"
                            render={(props) => {
                                return <ReferenceIntervalPage {...props} />;
                            }}
                        ></Route>
                        <Route
                            path="/reference/:id/:name"
                            render={(props) => (
                                <ReferenceIntervalPage {...props} />
                            )}
                        ></Route>
                        <Route path="/">
                            <LandingPage />
                        </Route>
                    </Switch>
                    <div style={{ marginBottom: "200px" }}></div>
                    <Footer />
                </div>
            </Router>
        </AppContext.Provider>
    );
}

export default App;
