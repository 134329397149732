import React from 'react';
import { useHistory } from 'react-router-dom';
import { useContext } from '../contexts/AppContext';

type LetterButtonProps = {
    letter: string
    selected: boolean,
    buttonIndex: number
}

function LetterButton({ letter, selected, buttonIndex }: LetterButtonProps): JSX.Element {
    const { buttonsReducer } = useContext();

    const history = useHistory();

    const style = {
        'color': selected ? '#d11a47' : '#062157',
        'fontWeight': 600,
        'paddingLeft': '8px',
        'paddingRight': '8px',
        'textDecoration': 'underline'
    }

    return (
        <div onClick={() => {
            history.push({
                pathname: "/",
                search: `?startsWith=${letter}`
            });
            buttonsReducer(buttonIndex)
        }} className="btn" style={style}>
            {letter}
        </div>
    );
}

export type { LetterButtonProps };
export default LetterButton;