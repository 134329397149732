import React, { useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import { useParams } from "react-router-dom";
import ReferenceInfo from "../components/ReferenceInfo";

import { useContext } from "../contexts/AppContext";
import Constants, {
    APDetailsType,
    DetailsType,
    TestTypeId,
} from "../constants";
import "../css/ReferenceInfo.css";

type referenceIntervalParams = {
    cptCode: string | undefined;
    name: string | undefined;
    id: string | undefined;
};

function ReferenceIntervalPage({ location }: any) {
    const { cptCode, name, id } = useParams<referenceIntervalParams>();
    const cptCodeString = decodeURIComponent(cptCode ? cptCode : "");
    const testTypeIdURL = decodeURIComponent(id ? id : "");
    const nameString = decodeURIComponent(name ? name : "");
    const [isLoading, setIsLoading] = useState(true);
    const isMounted = useRef(false);

    const { testTypeId } = useContext();
    const fromLocation =
        location && location.state ? location.state.fromLocation : undefined;

    const [details, setDetails] = useState<DetailsType[]>([]);
    const [apDetails, setAPDetails] = useState<APDetailsType[]>([]);

    function getTest() {
        if (parseInt(testTypeIdURL) === TestTypeId.AP) {
            fetch(
                `https://referenelabcatalogfunc.azurewebsites.net/api/GetAP?` +
                    `code=9Ho8HI8PfsPJaQnrN5zIXkTnrjuImJIbIupN7mpwYt5fAzFuLSkx2g==&` +
                    `primaryOrderName=${nameString}`
            )
                .then((response) => response.json())
                .then((json) => setAPDetails(json["APData"]))
                .catch((_err) => {})
                .finally(() => {
                    setIsLoading(false);
                });

            return;
        }

        if (cptCode === undefined || name === undefined) return;
        // const testTypeId = 0;
        const params = `&CPTCode=${cptCode}&Name=${name}&TestTypeId=${testTypeIdURL}`;
        //const params= `&Name=Type%20and%20Screen&CPTCode=86900&TestTypeId=4`;
        fetch(Constants.dataByIdEndpoint + params)
            .then((response) => response.json())
            .then((json) => {
                setDetails(json);
            })
            .catch((_err) => {})
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (!isMounted.current) {
            console.log(testTypeIdURL);
            getTest();
            isMounted.current = true;
        }
    });

    return (
        <div>
            {isLoading ? (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            ) : details.length > 0 ? (
                <ReferenceInfo
                    details={details}
                    apDetails={apDetails}
                    testName={nameString}
                    cptCode={cptCodeString}
                    fromLocation={fromLocation}
                    testTypeId={testTypeIdURL}
                />
            ) : parseInt(testTypeIdURL) === TestTypeId.AP &&
              apDetails.length > 0 ? (
                <ReferenceInfo
                    details={details}
                    apDetails={apDetails}
                    testName={nameString}
                    cptCode={""}
                    fromLocation={fromLocation}
                    testTypeId={testTypeIdURL}
                />
            ) : (
                <div className="errorBox">Test not found.</div>
            )}
        </div>
    );
}

export default ReferenceIntervalPage;
