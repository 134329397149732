export default function timeoutPromise(ms: number, promise: any): any {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(new Error("promise timeout"))
        }, ms);
        promise.then(
            (res: any) => {
                clearTimeout(timeoutId);
                resolve(res);
            },
            (err: any) => {
                clearTimeout(timeoutId);
                reject(err);
            }
        );
    })
}