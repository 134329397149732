export enum TestTypeId {
    All,
    GenLab,
    Micro,
    BloodBank,
    AP = 5,
}

type specimenType = {
    MinSpecimenVol: string;
    TransportTemperature: string;
    References: string[][];
}[];

type specimenTypes = {
    preferredSpecimen: string;
    Details: specimenType;
}[];

type specimenDetails = {
    Transport: string;
    SpecimenPreparation: string;
    RejectionCriteria: string;
}[];

type referenceIntervals = {
    ContainerType?: string;
    Transport?: string;
    SpecimenType?: string;
    MinimumSpecimenVolumn?: string;
    RejectionCriteria?: string;
    SpecimenStability?: string;
    CPTCodeId?: string;
    ContentId?: string;
    Description?: string;
    ModifiedDescription?: string;
    ReferenceIntervals?: [];
    TestTypeId?: number;
    TurnAroundTime?: string;
    PerformingLaboratory?: string;
    SpecimenTypes?: string;
    SpecimenDetails?: string;
}[];

export interface DetailsType {
    Description: string;
    ModifiedDescription: string;
    PerformingLaboratory: string;
    TurnAroundTime: string;
    SpecimenTypes: specimenTypes;
    SpecimenDetails: specimenDetails;
    ContainerType: string;
    Transport: string;
    SpecimenType: string;
    MinimumSpecimenVolumn: string;
    RejectionCriteria: string;
    SpecimenStability: string;
    ReferenceIntervals?: any;
    ReferenceInterval?: any;
    DTA?: string;
}

export interface APDetailsType {
    Id: string;
    PrimaryOrderName: string;
    ContainerType: string;
    Transport: string;
    SpecimenPreparation: string;
    SpecialInstructions: string;
    RejectionCriteria: string;
}

export interface TestType {
    id: string;
    cptCode: string;
    testName: string;
    preferredSpecimen: string;
    minimumVolume: string;
    transportTemperature: string;
    turnAroundTime: string;
    performingLaboratory: string;
    description: string;
    referenceInterval: { age: string; male: string; female: string }[];
    additionalInformation: string;
    ContainerType?: string;
    specimenStability?: string;
    rejectionCriteria?: string;
    TestTypeId?: string;
}

const constants = {
    usedChars: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "#",
    ],
    dataByIdEndpoint:
        "https://referenelabcatalogfunc.azurewebsites.net/api/GetLabTestResult?code=H56IoC1iroen2k10WGaRMGEyiOzDpB0qB4dFatTBASJMAM5I7id9tQ==",
};

export default constants;
