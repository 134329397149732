import React from "react";
import { DetailsType } from "../constants";
import "../css/ReferenceRange.css";

type ReferenceRangeProps = {
    detail: DetailsType[];
};

function ReferenceRange({ detail }: ReferenceRangeProps) {
    return (
        <div className="card shadow referenceRangeCard">
            <div
                className="card-header"
                style={{ fontWeight: "bold", display: "flex" }}
            >
                <div
                    className=""
                    style={{
                        textAlign: "center",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "100%",
                    }}
                >
                    Transport
                </div>
                <div
                    className=""
                    style={{
                        textAlign: "center",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "100%",
                    }}
                >
                    Specimen Preparations
                </div>
                <div
                    className=""
                    style={{
                        textAlign: "center",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        width: "100%",
                    }}
                >
                    Rejection Criteria
                </div>
            </div>
            <div className="card-body" style={{ color: "#062157" }}>
                {detail[0].SpecimenDetails.map((reference: any, i: any) => {
                    return (
                        <div
                            key={`refRangeRow${reference}${i}`}
                            className="row"
                            style={{
                                width: "100%",
                                borderBottom: "1px solid gray",
                            }}
                        >
                            <div className="col rangeCol" style={{
                                paddingTop: 10,
                                paddingBottom: 10
                            }}>{reference.Transport}</div>

                            <div className="col rangeCol" 
                                style={{
                                    backgroundColor: "#e6e6e6", paddingTop: 10,
                                    paddingBottom: 10
                                }}>
                                    {
                                    reference.SpecimenPreparation.indexOf("-") !== -1
                                    ? reference.SpecimenPreparation.split("-").map((t:string, indexs:number)=> {
                                        if(indexs ==0) {
                                            return <b>{t}-</b>
                                        }
                                        return <span>{t + ' '}</span>;
                                    })
                                    : reference.SpecimenPreparation
                                    }
                            </div>
                            <div className="col rangeCol" style={{
                                paddingTop: 10,
                                paddingBottom: 10
                            }}>{reference.RejectionCriteria}</div>
                        </div>
                    );
                }).reverse()}
            </div>
        </div>
    );
}

export default ReferenceRange;
