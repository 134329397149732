import React from "react";

type contextType = {
    buttons: [string, boolean][];
    buttonsReducer: (newButtonIndex: number) => void;
    selectedButtonIndex: number;
    searchQuery: string;
    setSearchQuery: (newQuery: string) => void;
    testTypeId: number;
    globalPage: number;
};

const AppContext = React.createContext<contextType>({
    buttons: [],
    buttonsReducer: () => {},
    selectedButtonIndex: -1,
    searchQuery: "",
    setSearchQuery: () => {},
    testTypeId: 0,
    globalPage: 1,
});

export const useContext = () => React.useContext(AppContext);
export default AppContext;
