import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "./SearchBar";
import Navbar from "react-bootstrap/Navbar";
import LetterButtons from "./LetterButtons";
import logo from "../assets/reference_lab_logo.png";

import "../css/Header.css";
import { useHistory, useLocation } from "react-router-dom";

const Header = ({
    onTestTypeChange,
}: {
    onTestTypeChange: (value: number) => void;
}) => {
    const location = useLocation();
    const history = useHistory();

    const shouldDisable = useMemo(() => {
        const lName = location.pathname;
        let lNameSplit = lName.split("/");
        return lNameSplit[1] === "reference";
    }, [location.pathname]);

    return (
        <div className="header">
            <Navbar>
                <Navbar.Brand>
                    <a href="/">
                        <img src={logo} className="usaLogo" alt="usa logo" />
                    </a>
                </Navbar.Brand>
                <SearchBar />
            </Navbar>
            <div className="d-flex center-letters">
                <LetterButtons />
                <div className="typeSelect">
                    <label className="ml-3" htmlFor="cars">
                        <b>Test Type:</b>
                    </label>
                    <select
                        className="ml-2"
                        name="cars"
                        id="cars"
                        style={{ borderRadius: 8, padding: 5 }}
                        onChange={(event) => {
                            onTestTypeChange(parseInt(event.target.value));
                            history.push({
                                pathname: "/",
                                search: `?startsWith=-1`,
                            });
                        }}
                        disabled={shouldDisable}
                    >
                        <option value="0">All</option>
                        <option value="1">GenLab</option>
                        <option value="2">Micro</option>
                        <option value="3">Blood Bank</option>
                        <option value="5">AP</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Header;
