import React, { useState } from "react";
import { Card } from "react-bootstrap";
import TestTable from "../components/TestTable";
import "../css/LandingPage.css";

function LandingPage() {
    return (
        <Card className="mainContainer">
            <Card.Body>
                <TestTable />
            </Card.Body>
        </Card>
    );
}

export default LandingPage;
