import React, { CSSProperties, useState } from "react";
import "../css/testRow.css";
import "../css/Base.css";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/esm/Spinner";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { Link } from "react-router-dom";
import { useContext } from "../contexts/TestTableContext";
import { useContext as AppContext } from "../contexts/AppContext";
import Constants, { TestType, TestTypeId as eTestTypeId } from "../constants";
import timeoutPromise from "../timeoutPromise";

type testRowProps = {
    id: string;
    cptCode: string;
    testName: string;
    TestTypeId: string;
};

function TestRow({
    id,
    cptCode,
    testName,
    TestTypeId,
}: testRowProps): JSX.Element {
    const [test, setTest] = useState<TestType | null>(null);
    const [loading, setLoading] = useState(true);

    const { activeKey, setActiveKey } = useContext();
    const { testTypeId } = AppContext();

    function onAccordionEnter(element: HTMLElement) {
        element.style.visibility = "visible";
        element.style.backgroundColor = "#f2f2f2";
    }

    function onAccordionExit(element: HTMLElement) {
        element.style.visibility = "hidden";
        element.style.backgroundColor = "white";
    }

    const accordionToggle = useAccordionToggle(id);
    // console.log(`${testName} : ${parseInt(TestTypeId) === eTestTypeId.AP}`);

    return (
        <>
            <div
                onClick={(e) => {
                    const params = `&CPTCode=${cptCode}&Name=${testName}&TestTypeId=${TestTypeId}`;
                    ////const params= `&Name=Type%20and%20Screen&CPTCode=86900&TestTypeId=4`;
                    timeoutPromise(
                        30000,
                        fetch(Constants.dataByIdEndpoint + params)
                    )
                        .then((response: Response) => response.json())
                        .then((json: any) => {
                            const details = json[0];
                            setTest({
                                id: id,
                                cptCode: cptCode,
                                testName: testName,
                                preferredSpecimen: details?.SpecimenType,
                                minimumVolume: details.MinimumSpecimenVolumn
                                    ? details.MinimumSpecimenVolumn
                                    : "",
                                transportTemperature: details.Transport
                                    ? details.Transport
                                    : "",
                                performingLaboratory:
                                    details.performingLaboratory
                                        ? details.performingLaboratory
                                        : "",
                                turnAroundTime: details.TurnAroundTime
                                    ? details.TurnAroundTime
                                    : "",
                                referenceInterval: details.ReferenceIntervals,
                                description: "",
                                additionalInformation: details.Description
                                    ? details.Description
                                    : "",
                                ContainerType: details.ContainerType
                                    ? details.ContainerType
                                    : "",
                                specimenStability: details.SpecimenStability
                                    ? details.SpecimenStability
                                    : "",
                                rejectionCriteria: details.RejectionCriteria
                                    ? details.RejectionCriteria
                                    : "",
                            });
                        })
                        .catch((_err: Error) => {})
                        .finally(() => {
                            setLoading(false);
                        });

                    if (activeKey === id) {
                        setActiveKey("");
                    } else {
                        setActiveKey(id);
                    }

                    accordionToggle(e);
                }}
                className="btn row testRow"
                style={activeKey === id ? clickedStyle : {}}
                key={id + "nameRow"}
            >
                {testTypeId !== 5 && testTypeId !== 6 && (
                    <div className="col">
                        <div className="testCPTCode">{cptCode}</div>
                    </div>
                )}
                <div className="col">
                    <div
                        className={
                            "testName" +
                            (testTypeId === 5 || testTypeId === 6
                                ? " left"
                                : "")
                        }
                    >
                        {testName}
                    </div>
                </div>
            </div>

            <div key={id + "accordionRow"} className="accordionRow">
                <Accordion.Collapse
                    eventKey={id}
                    unmountOnExit={true}
                    onEnter={onAccordionEnter}
                    onExited={onAccordionExit}
                >
                    {loading ? (
                        <Spinner
                            animation="border"
                            role="status"
                            style={{ margin: "20px" }}
                        >
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    ) : (
                        <>
                            <div className="row innerAccordionRow">
                                {parseInt(TestTypeId) !== eTestTypeId.AP && (
                                    <div
                                        className="col-sm testCol"
                                        style={{ marginLeft: "48px" }}
                                    >
                                        {test?.preferredSpecimen &&
                                            "" + TestTypeId !== "3" && (
                                                <div>
                                                    <div className="testAttributeTitle">
                                                        Specimen Type
                                                    </div>
                                                    <div className="testAttributeInfo">
                                                        {
                                                            test?.preferredSpecimen
                                                        }
                                                    </div>
                                                </div>
                                            )}

                                        {test?.minimumVolume &&
                                            "" + TestTypeId !== "3" && (
                                                <div>
                                                    <div className="testAttributeTitle">
                                                        Minimum Specimen Volume
                                                    </div>
                                                    <div className="testAttributeInfo">
                                                        {test?.minimumVolume}
                                                    </div>
                                                </div>
                                            )}

                                        {test?.transportTemperature && (
                                            <div>
                                                <div className="testAttributeTitle">
                                                    {"" + TestTypeId !== "3"
                                                        ? "Transport "
                                                        : "Transport"}
                                                </div>
                                                <div className="testAttributeInfo">
                                                    {test?.transportTemperature}
                                                </div>
                                            </div>
                                        )}

                                        {test?.ContainerType && (
                                            <div>
                                                <div className="testAttributeTitle">
                                                    Container Type
                                                </div>
                                                <div className="testAttributeInfo">
                                                    {test?.ContainerType}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div className="col-md">
                                    <div className="row">
                                        {parseInt(TestTypeId) !==
                                            eTestTypeId.AP && (
                                            <div className="col-sm testCol">
                                                {test?.turnAroundTime &&
                                                    "" + TestTypeId !== "3" && (
                                                        <div>
                                                            <div className="testAttributeTitle">
                                                                Turn-around Time
                                                            </div>
                                                            <div className="testAttributeInfo">
                                                                {
                                                                    test?.turnAroundTime
                                                                }
                                                            </div>
                                                        </div>
                                                    )}

                                                {test?.specimenStability &&
                                                    "" + TestTypeId !== "3" && (
                                                        <div>
                                                            <div className="testAttributeTitle">
                                                                Specimen
                                                                Stability
                                                            </div>
                                                            <div className="testAttributeInfo">
                                                                {
                                                                    test?.specimenStability
                                                                }
                                                            </div>
                                                        </div>
                                                    )}

                                                {test?.rejectionCriteria &&
                                                    "" + TestTypeId !== "3" && (
                                                        <div>
                                                            <div className="testAttributeTitle">
                                                                Rejection
                                                                Criteria
                                                            </div>
                                                            <div className="testAttributeInfo">
                                                                {
                                                                    test?.rejectionCriteria
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        )}

                                        <div className="col-lg testCol">
                                            <Link
                                                to={
                                                    parseInt(TestTypeId) !==
                                                    eTestTypeId.AP
                                                        ? {
                                                              pathname: `/reference/${encodeURIComponent(
                                                                  cptCode
                                                              )}
                                                                        /${encodeURIComponent(
                                                                            TestTypeId
                                                                        )}
                                                                        /${encodeURIComponent(
                                                                            testName
                                                                        )}`,
                                                              state: {
                                                                  fromLocation:
                                                                      window
                                                                          .location
                                                                          .pathname +
                                                                      window
                                                                          .location
                                                                          .search,
                                                              },
                                                          }
                                                        : {
                                                              pathname: `/reference/${encodeURIComponent(
                                                                  TestTypeId
                                                              )}
                                                                        /${encodeURIComponent(
                                                                            testName
                                                                        )}`,
                                                              state: {
                                                                  fromLocation:
                                                                      window
                                                                          .location
                                                                          .pathname +
                                                                      window
                                                                          .location
                                                                          .search,
                                                              },
                                                          }
                                                }
                                            >
                                                <button
                                                    className={
                                                        "btn referenceBtn" +
                                                        (parseInt(
                                                            TestTypeId
                                                        ) === eTestTypeId.AP
                                                            ? " alignReferenceBtn"
                                                            : "")
                                                    }
                                                >
                                                    {"" + TestTypeId === "2" ||
                                                    "" + TestTypeId === "3"
                                                        ? "Specimen Details"
                                                        : "Reference Interval"}
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Accordion.Collapse>
            </div>
        </>
    );
}

const clickedStyle: CSSProperties = {
    backgroundColor: "#062157",
    color: "white",
};

export default TestRow;
