import React from "react";
import { DetailsType } from "../constants";
import "../css/ReferenceRange.css";

type ReferenceRangeProps = {
    detail: any;
};

function ReferenceInfoBloodBank({ detail }: ReferenceRangeProps) {
    return (
        <div className="card shadow referenceRangeCard">
            <div
                className="card-header"
                style={{ fontWeight: "bold", display: "flex" }}
            >
                <div
                    className="row"
                    style={{
                        width: "100%",
                    }}
                >
                    {detail.DTA}
                </div>
            </div>
            <div className="card-body" style={{ color: "#062157" }}>
                <div
                    className="row"
                    style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        // backgroundColor: i % 2 === 0 ? "#e6e6e6" : "white",
                        width: "100%",
                    }}
                >
                    <div className="col rangeCol">{detail.ReferenceInterval}</div>
                </div>
            </div>
        </div>
    );
}

export default ReferenceInfoBloodBank;
