import React from 'react';
import LetterButton from './LetterButton';
import { useContext } from '../contexts/AppContext';

function LetterButtons(): JSX.Element {
    const { buttons } = useContext();

    const buttonElements = buttons.map((button, i) => <LetterButton letter={button[0]} selected={button[1]} buttonIndex={i} key={`letterButton${i}`}/>)

    return (
        <div>
            {buttonElements}
        </div>
    );
}

export default LetterButtons;