import React from 'react';
import '../css/Footer.css';

function Footer() {
    return (
        <div className="footer">
            <h6 className="footerTitle">For Further Information Contact</h6>
            <div>Leitha B. Boutwell, MT (ASCP)</div>
            <div>Assistant Director</div>
            <a className="footerEmail" href="mailto:lboutwell@health.southalabama.edu">LBoutwell@health.southalabama.edu</a>
            <div>
                P: <a className="footerPhone" href="tel:251-470-1648">251-470-1648</a>
            </div>
        </div>
    );
}

export default Footer;