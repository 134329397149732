import React from "react";
import { DetailsType } from "../constants";
import "../css/ReferenceRange.css";

type ReferenceRangeProps = {
    detail: DetailsType;
};

function ReferenceInfoAll({ detail }: ReferenceRangeProps) {
    return (
        <div className="card shadow referenceRangeCard">
            <div className="card-header" style={{ fontWeight: "bold", paddingLeft:20,}}>
                {detail.Description}
            </div>
            <div className="card-body" style={{ color: "#062157" }}>
                {detail.SpecimenTypes[0].Details[0].References.map((reference, i) => {
                    return (
                        <div
                            key={`refRangeRow${detail.Description}${i}`}
                            className="row"
                            style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                backgroundColor: i % 2 === 0 ? "#e6e6e6" : "white",
                                width: "100%",
                            }}
                        >
                        {reference.map((str, j) => {
                            return (
                                <div
                                    key={`refRangeCol${detail.Description}${i}${j}`}
                                    className="col rangeCol"
                                >
                                    {str}
                                </div>
                        );
                        })}
                        </div>
                    );
                }).reverse()}
            </div>
        </div>
    );
}

export default ReferenceInfoAll;
